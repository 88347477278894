@import './_antd.css';
@import './fonts.css';
@import './variables.scss';

html {
  font-size: $text-md;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-size: $text-h1;
  font-weight: 600;
  margin-bottom: $margin-padding-lg;
  letter-spacing: $spacing-lg;
}

h2 {
  font-size: $text-h2;
  font-weight: 500;
  margin-bottom: $margin-padding-lg;
  letter-spacing: $spacing-lg;
}

h3 {
  font-size: $text-h3;
  font-weight: 500;
  margin-bottom: $margin-padding-md;
  letter-spacing: $spacing-md;
}

h4 {
  font-size: $text-h4;
  font-weight: 500;
  margin-bottom: $margin-padding-md;
  letter-spacing: $spacing-md;
}

h5 {
  font-size: $text-h5;
  font-weight: 500;
  margin-bottom: $margin-padding-sm;
  letter-spacing: $spacing-sm;
}

h6 {
  font-size: $text-h6;
  font-weight: 500;
  margin-bottom: $margin-padding-sm;
  letter-spacing: $spacing-sm;
}

p {
  margin: 0;
}

small {
  font-size: $text-sm;
  color: $grey-300;
}

// Scroll track

::-webkit-scrollbar {
  width: $margin-padding-xs;
  height: $margin-padding-sm;
}

::-webkit-scrollbar-track {
  background: $grey-100;
}

::-webkit-scrollbar-thumb {
  background: darken($grey-100, 15%);
  border-radius: $radius-sm;
}

// Google map

.pac-container {
  font-family: inherit;

  .pac-item {
    padding: $margin-padding-xs;
  }
}

// Ant overrides

.ant-input-number {
  width: 100%;
}
