@font-face {
  font-family: 'IBM Plex Sans';
  font-weight: 200;
  src: local('IBM Plex Sans'),
    url(./fonts/IBM_Plex_Sans/IBMPlexSans-200.ttf) format('truetype');
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-weight: 400;
  src: local('IBM Plex Sans'),
    url(./fonts/IBM_Plex_Sans/IBMPlexSans-400.ttf) format('truetype');
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-weight: 500;
  src: local('IBM Plex Sans'),
    url(./fonts/IBM_Plex_Sans/IBMPlexSans-500.ttf) format('truetype');
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-weight: 600;
  src: local('IBM Plex Sans'),
    url(./fonts/IBM_Plex_Sans/IBMPlexSans-600.ttf) format('truetype');
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  src: local('IBM Plex Sans'),
    url(./fonts/IBM_Plex_Sans/IBMPlexSans-Italic.ttf) format('truetype');
}
