@import './variables.scss';

// Colors

.color-primary-500 {
  color: $primary-500;
}

.color-primary {
  color: $primary-400;
}

.color-grey-500 {
  color: $grey-500;
}

.color-grey-400 {
  color: $grey-400;
}

.color-grey-300 {
  color: $grey-300;
}

.color-grey-200 {
  color: $grey-200;
}

.color-grey-100 {
  color: $grey-100;
}

.color-error {
  color: $error;
}

.color-success {
  color: $success;
}

.color-warning {
  color: $warning;
}

// Flex & alignment

.page-wrapper {
  height: 100vh;
  background: $background-html;
}

.width-100 {
  width: 100%;
}

.width-50 {
  width: 50%;
}

.height-100 {
  height: 75vh;
}

.block {
  display: block;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.align-middle {
  align-items: center;
  justify-content: center;
}

.justify-end {
  align-items: center;
  justify-content: flex-end;
}

.justify-start {
  align-items: center;
  justify-content: flex-start;
}

.space-between {
  align-items: center;
  justify-content: space-between;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.row-gap-xs {
  row-gap: $margin-padding-xs;
}

.row-gap-sm {
  row-gap: $margin-padding-sm;
}

.row-gap-md {
  row-gap: $margin-padding-md;
}

.row-gap-lg {
  row-gap: $margin-padding-lg;
}

.row-gap-xl {
  row-gap: $margin-padding-xl;
}

.row-gap-xxl {
  row-gap: $margin-padding-xxl;
}

.column-gap-xs {
  column-gap: $margin-padding-xs;
}

.column-gap-sm {
  column-gap: $margin-padding-sm;
}

.column-gap-md {
  column-gap: $margin-padding-md;
}

.column-gap-lg {
  column-gap: $margin-padding-lg;
}

.column-gap-xl {
  column-gap: $margin-padding-xl;
}

.column-gap-xxl {
  column-gap: $margin-padding-xxl;
}

// Card

.card {
  background: $white-custom;
  box-shadow: $shadow-md;
  border-radius: $radius-md;
  height: fit-content;
}

// Margin & padding

.margin-none {
  margin: 0;
}

.margin-auto {
  margin: auto;
}

.margin-bottom-xs {
  margin-bottom: $margin-padding-xs;
}

.margin-bottom-sm {
  margin-bottom: $margin-padding-sm;
}

.margin-bottom-md {
  margin-bottom: $margin-padding-md;
}

.margin-bottom-lg {
  margin-bottom: $margin-padding-lg;
}

.margin-top-sm {
  margin-top: $margin-padding-sm;
}

.padding-xs {
  padding: $margin-padding-xs;
}

.padding-sm {
  padding: $margin-padding-sm;
}

.padding-md {
  padding: $margin-padding-md;
}

.padding-lg {
  padding: $margin-padding-lg;
}

.padding-xl {
  padding: $margin-padding-xl;
}

.padding-y-md {
  padding: $margin-padding-md 0;
}

.padding-y-lg {
  padding: $margin-padding-lg 0;
}

.padding-y-loading {
  padding: 3 * $margin-padding-xl 0;
}

// Typography

.bold {
  font-weight: 600;
}

.semi-bold {
  font-weight: 500;
}

.uppercase {
  text-transform: uppercase;
}

.font-larger {
  font-size: $text-lg;
}

.font-body {
  font-weight: 400;
  font-size: $text-md;
}

.no-decoration {
  text-decoration: none;
}

// Buttons

.button-small {
  padding: $margin-padding-xs $margin-padding-sm;
  color: $grey-300;
  font-size: $text-xs;
  font-weight: 500;
  height: $margin-padding-xl;
  line-height: $text-md;
}

// Misc

.form-default {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: $margin-padding-md;

  @media screen and (max-width: 575px) {
    &.ant-form-vertical .ant-form-item {
      flex-direction: row;
    }
  }
}

.tag-large {
  font-size: $text-md;
  font-weight: 500;
}

.title-block {
  display: grid;
  grid-auto-flow: column;
  column-gap: $margin-padding-sm;
  align-items: center;

  h3 {
    margin: 0;
  }
}

.modal-button-group {
  text-align: right;
  margin-bottom: 0;
  margin-top: $margin-padding-md;
  button {
    margin-left: $margin-padding-md;
  }
}
