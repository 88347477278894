// Colors

$primary-500: #001529;
$primary-400: #1890ff;
$accent: $primary-400;

$grey-500: #151515;
$grey-400: lighten($grey-500, 15%);
$grey-300: lighten($grey-500, 35%);
$grey-200: lighten($grey-500, 55%);
$grey-100: lighten($grey-500, 75%);
$white-custom: #fff;

$background-html: lighten($grey-500, 90%);

$success: #52c41a;
$warning: #faad14;
$error: #f5222d;

// Font sizes

$text-xs: 11.5px;
$text-sm: 12.5px;
$text-md: 13.5px;
$text-lg: 14.5px;

$text-h1: 30px;
$text-h2: 24px;
$text-h3: 20px;
$text-h4: 16px;
$text-h5: 15px;
$text-h6: 14px;

$line-height: 1.575;

// Margin, padding

$margin-padding-xs: 4px;
$margin-padding-sm: 8px;
$margin-padding-md: 12px;
$margin-padding-lg: 16px;
$margin-padding-xl: 24px;
$margin-padding-xxl: 36px;

// Breakpoints

$bp-xxl: 1560px;
$bp-xl: 1366px;
$bp-lg: 1076px;
$bp-md: 768px;
$bp-sm: 576px;
$bp-xs: 398px;

// Letter spacing

$spacing-sm: 0.1px;
$spacing-md: 0.2px;
$spacing-lg: 0.3px;

// Border radii

$radius-sm: 2px;
$radius-md: 4px;
$radius-lg: 8px;

// Box shadow

$shadow-sm: 0 1px 3px rgba(0, 0, 0, 0.12);
$shadow-md: 0 2px 6px rgba(0, 0, 0, 0.12);
$shadow-lg: 0 3px 9px rgba(0, 0, 0, 0.12);

// Dimensions

$site-min-width: 1080px;
$header-height: 60px;
$header-height-mb: 50px;
